import styled, { css } from 'styled-components/macro';
import { Text } from '@Omegapoint/components';

export const IconWrapper = styled.figure`
  transition: transform 0.2s linear;
  color: ${({ theme }) => theme.Color.primary};

  ${({ show }) => {
    if (show) {
      return css`
        transform: rotate(180deg);
      `;
    } else
      return css`
        transform: rotate(0deg);
      `;
  }};
`;

export const DrawerHeaderSpan = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.Variables.smallGutter} 0;
  color: ${({ theme }) => theme.Color.primary};
`;

export const DrawerHeaderText = styled(Text)`
  letter-spacing: 0.96px;
  color: ${({ theme }) => theme.Color.primary};
  margin: 0;
`;

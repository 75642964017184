import styled, { css } from 'styled-components/macro';

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DrawerContentWrapper = styled.div`
  overflow: hidden;

  ${({ show }) => {
    if (show) {
      return css`
        max-height: 100rem;
        transition: max-height 1s ease-in-out 0.4s;
      `;
    } else {
      return css`
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1) 0.4s;
      `;
    }
  }}
`;

export const DrawerContent = styled.div`
  padding-bottom: ${({ theme }) => theme.Variables.smallGutter};
`;

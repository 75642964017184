import { Accordion } from '@components/Accordion';
import { Infobox } from '@components/atoms';
import { Button, Text } from '@Omegapoint/components';
import { Col, Row } from '@Omegapoint/components/layout';
import React from 'react';
import {
  BackgroundSection,
  ContentCol,
  Divider,
  InfoboxRow,
  PriceCtaContainer,
  StyledAccordionHeader,
  RelativeCol,
  ButtonCol,
  CtaRow,
  SkeletonPlaceholder,
} from './ArticleDescription.style';
import { useTheme } from 'styled-components';

const ArticleDescriptionSkeleton = () => {
  const theme = useTheme();
  return (
    <BackgroundSection opacity={0.5}>
      <Row>
        <Col col="12" md="4">
          <Row>
            <RelativeCol col="12">
              <SkeletonPlaceholder
                background={theme.Color.grey10}
                height={450}
              />
            </RelativeCol>
          </Row>
        </Col>
        <ContentCol col="12" md="8">
          <Row>
            <Col>
              <SkeletonPlaceholder height={50} />
            </Col>
          </Row>
          <Divider mobileOnly />
          <PriceCtaContainer colGutter="0px">
            <Col col="12" lg="6">
              <SkeletonPlaceholder
                background={theme.Color.grey10}
                height={50}
              />
            </Col>

            <Col col="12" lg="6">
              <Divider mobileOnly />
              <CtaRow>
                <ButtonCol col="6">
                  <Button variant="info">
                    <SkeletonPlaceholder />
                  </Button>
                </ButtonCol>
                <ButtonCol col="6">
                  <Button></Button>
                </ButtonCol>
              </CtaRow>
            </Col>
          </PriceCtaContainer>

          <Divider mobileOnly />
          <Accordion open noBorder openOnDesktop>
            <StyledAccordionHeader>
              <Text variant="h5" type="p" uppercase={true} dense></Text>
            </StyledAccordionHeader>
            <InfoboxRow
              colGutter="0px"
              gutters={20}
              cols={{ col: 2, sm: 2, lg: 3, xl: 3, xxl: 4 }}
            >
              <Col>
                <Infobox>
                  <SkeletonPlaceholder width={0} />
                </Infobox>
              </Col>
              <Col>
                <Infobox></Infobox>
              </Col>
              <Col>
                <Infobox></Infobox>
              </Col>
              <Col>
                <Infobox></Infobox>
              </Col>
            </InfoboxRow>
          </Accordion>
        </ContentCol>
      </Row>
      <Divider />
    </BackgroundSection>
  );
};

// export default withTheme(ArticleDescriptionSkeleton);
export { ArticleDescriptionSkeleton };

import { Accordion } from '@components/Accordion';
import { AddToCart, Infobox, Price, Quantity } from '@components/atoms';
import { Drawer, DrawerContent, DrawerHeader } from '@components/Drawer';
import { AlertCircle, CheckCircle, Pdf } from '@components/Icon';
import useTranslate from '@hooks/Translation';
import { Button, Image, Text, Dropdown } from '@Omegapoint/components';
import { Col, Row } from '@Omegapoint/components/layout';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import Link from 'gatsby-link';
import {
  BackgroundSection,
  ContentCol,
  Divider,
  IconText,
  InfoboxRow,
  InfoboxText,
  PriceCtaContainer,
  PriceWrapper,
  StockBox,
  StockText,
  StyledAccordionHeader,
  TitleText,
  StyledInstructionLink,
  RecWrapper,
  NewsIcon,
  RelativeCol,
  ButtonCol,
  AdditionalImagesRow,
  CtaRow,
  DropdownWrapper,
} from './ArticleDescription.style';
import { withTheme } from 'styled-components';
import { trackProductView } from '@Omegapoint/services/googleTracking';
import RetailModal from '@components/RetailModal/RetailModal';
import { unitConverter } from '@utilities';
import usePrices from '@hooks/usePrices';
import useStore from '@store';

const ArticleDescriptionComponent = ({
  title,
  image,
  width,
  height,
  depth,
  gtin,
  sku,
  attributes,
  description,
  materialDescription,
  surfaceDescription,
  assemblyInstructions,
  inStock,
  theme,
  isNew,
  additionalImages,
  unit,
  unitQuantity,
  descriptionLong,
  relatedArticles,
}) => {
  const { translate } = useTranslate();
  const auth = useStore((state) => state.auth);
  const market = useStore((state) => state.market);

  const [quantity, setQuantity] = useState(1);
  const [sentTracking, setSentTracking] = useState(false);
  const [imageSrc, setImageSrc] = useState(image);
  const { priceToShow } = usePrices(sku);

  const increaseQuantity = () => {
    setQuantity((quantity) => quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity - 1 < 0) return;
    setQuantity((quantity) => quantity - 1);
  };

  const handleQuantityChange = (data) => {
    const newQuantity = parseInt(data);
    if (!isNaN(newQuantity) && newQuantity >= 0) setQuantity(newQuantity);
  };

  const data = useStaticQuery(graphql`
    {
      allUmbracoMarkets {
        edges {
          node {
            UmbracoContent {
              Settings {
                SiteSettings {
                  FindResellerUrl
                }
              }
              SharedContent {
                SurfaceDescription {
                  Items {
                    Description
                    Key
                  }
                }
                MaterialDescription {
                  Items {
                    Key
                    Description
                  }
                }
              }
            }
            Culture {
              countryCode
            }
          }
        }
      }
    }
  `);
  const { SharedContent, Settings } = data.allUmbracoMarkets.edges
    .filter((edge) => {
      return (
        edge.node.Culture !== null &&
        edge.node.Culture.countryCode === market.id
      );
    })
    .map((page) => {
      return page.node.UmbracoContent;
    })[0];

  const materialDesc = SharedContent?.MaterialDescription?.Items?.filter(
    (description) =>
      description.Key?.toLocaleLowerCase() ===
      materialDescription?.toLocaleLowerCase()
  )[0]?.Description;

  const surfaceDesc = SharedContent?.SurfaceDescription?.Items?.filter(
    (description) =>
      description.Key?.toLocaleLowerCase() ===
      surfaceDescription?.toLocaleLowerCase()
  )[0]?.Description;

  if (
    typeof priceToShow !== 'undefined' &&
    priceToShow !== '0' &&
    !sentTracking
  ) {
    trackProductView({
      name: title, // Name or ID is required.
      id: sku,
      price: parseInt(priceToShow),
      // brand: 'Google',
      // category: 'Apparel',
      // variant: 'Gray',
    });
    setSentTracking(true);
  }

  const options = relatedArticles?.map((option) => {
    const endSegment =
      option?.itemUnitQuantity &&
      option?.itemUnit &&
      option?.itemUnitQuantity > 0
        ? `${option.itemMaterial}, ${option.itemUnitQuantity}${option.itemUnit}`
        : option.itemMaterial;
    return {
      value: `${unitConverter(
        option.itemWidth,
        option.itemHeight,
        option.itemDepth
      )}, ${endSegment}`,
      id: option.sku,
      sku: option.sku,
      url: option.url,
    };
  });

  const currentEndSegment =
    unitQuantity && unit && unitQuantity > 0
      ? `${attributes?.material?.Value}, ${unitQuantity}${unit}`
      : attributes?.material?.Value;
  options?.unshift({
    value: `${unitConverter(width, height, depth)}, ${currentEndSegment}`,
    id: sku,
    sku: sku,
  });

  const handleDropdownChange = (option) => {
    navigate(option?.url);
  };

  return (
    <BackgroundSection itemType="https://schema.org/Product">
      <meta itemProp="sku" content={sku} />
      <link itemProp="image" href={image?.optimized} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <Row>
        <Col col="12" md="4">
          <Row>
            <RelativeCol
              col={additionalImages?.length > 1 ? '6' : '12'}
              md="12"
            >
              {isNew && (
                <NewsIcon
                  size={150}
                  fill={theme.Color.primary}
                  color={theme.Color.white}
                />
              )}
              <Image
                height={'545px'}
                width={'545px'}
                alt={title}
                src={imageSrc}
                altMissingImage
              />
            </RelativeCol>
            {additionalImages?.length > 1 && (
              <Col col="6" md="12">
                <AdditionalImagesRow cols={{ col: '2', lg: '4' }}>
                  {additionalImages.map((img, i) => (
                    <Col key={i}>
                      <Image
                        hasCursor
                        height={'100px'}
                        width={'100px'}
                        src={{
                          original: img?.imageFormats?.webColor,
                          optimized: img?.imageFormats?.webColorOptimized,
                        }}
                        altMissingImage
                        onClick={() =>
                          setImageSrc({
                            original: img?.imageFormats?.webColor,
                            optimized: img?.imageFormats?.webColorOptimized,
                          })
                        }
                      />
                    </Col>
                  ))}
                </AdditionalImagesRow>
              </Col>
            )}
          </Row>
        </Col>
        <ContentCol col="12" md="8">
          <Row>
            <Col>
              <TitleText component="h1" variant="h2">
                {title}
              </TitleText>
              {typeof width !== 'undefined' && typeof height !== 'undefined' && (
                <>
                  {options && options.length > 1 ? (
                    <DropdownWrapper>
                      <Dropdown
                        condensed
                        options={options}
                        selectedValue={options[0].value}
                        onChange={handleDropdownChange}
                        bold={true}
                      />
                    </DropdownWrapper>
                  ) : (
                    <Text component="p" variant="h3" light>
                      {unitConverter(width, height, depth)}
                    </Text>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Divider mobileOnly />
          <PriceCtaContainer colGutter="0px">
            {typeof process.env.GATSBY_HIDE_PRICES === 'undefined' ||
              (process.env.GATSBY_HIDE_PRICES === 'false' && (
                <Col col="12" lg="6">
                  <PriceWrapper>
                    <Price sku={sku} showRec light={theme.Variables.light} />
                  </PriceWrapper>
                </Col>
              ))}
            <Col col="12" lg="6">
              <Divider mobileOnly />
              <CtaRow>
                {auth.status === 'in' ? (
                  <>
                    <ButtonCol lg="6">
                      <Quantity
                        quantity={quantity}
                        increaseEvent={increaseQuantity}
                        decreaseEvent={decreaseQuantity}
                        setQuantity={handleQuantityChange}
                      />
                    </ButtonCol>
                    <ButtonCol col="6">
                      <AddToCart
                        sku={sku}
                        quantity={quantity}
                        padding={0}
                        size={34}
                      />
                    </ButtonCol>
                  </>
                ) : (
                  <>
                    <ButtonCol col="6">
                      <Link to={Settings?.SiteSettings?.FindResellerUrl}>
                        <Button variant="info">
                          {translate('Find_Reseller')}
                        </Button>
                      </Link>
                    </ButtonCol>
                    <ButtonCol col="6">
                      <RetailModal gtin={gtin} />
                    </ButtonCol>
                  </>
                )}
              </CtaRow>
            </Col>
          </PriceCtaContainer>
          {typeof process.env.GATSBY_HIDE_PRICES === 'undefined' ||
            (process.env.GATSBY_HIDE_PRICES === 'false' && (
              <RecWrapper>
                {process.env.GATSBY_BRAND === 'systemtext' && (
                  <Text variant="h4">
                    {auth.status === 'in'
                      ? translate('Currency_Message_Auth')
                      : translate('Currency_Message_Public')}
                  </Text>
                )}
                {(auth.status === 'out' || auth.status === 'none') && (
                  <Text component="p" variant="smalltext">
                    {translate('Rec_Price_Retail_Price_Can_Vary')}
                  </Text>
                )}
              </RecWrapper>
            ))}

          <Divider mobileOnly />
          <Accordion open noBorder openOnDesktop>
            <StyledAccordionHeader>
              <Text variant="h5" type="p" uppercase={true} dense>
                {translate('Extended_Information')}
              </Text>
            </StyledAccordionHeader>

            <InfoboxRow
              colGutter="0px"
              gutters={20}
              cols={{ col: 2, sm: 2, lg: 3, xl: 3, xxl: 4 }}
            >
              <Col>
                <Infobox>
                  <InfoboxText align="center" variant="p" component="h5" light>
                    {translate('Article_Number')}
                  </InfoboxText>
                  <InfoboxText align="center" variant="h4" component="p" dense>
                    {sku}
                  </InfoboxText>
                </Infobox>
              </Col>
              {attributes &&
                Object.keys(attributes).map((headerKey, i) => {
                  const cvl = attributes[headerKey];
                  return (
                    <Col key={i}>
                      <Infobox>
                        <InfoboxText
                          align="center"
                          variant="p"
                          component="h5"
                          light
                        >
                          {translate(
                            `${headerKey
                              .charAt(0)
                              .toUpperCase()}${headerKey.slice(1)}`
                          )}
                        </InfoboxText>
                        <InfoboxText
                          align="center"
                          variant="h4"
                          component="p"
                          dense
                        >
                          {cvl.Value}
                        </InfoboxText>
                      </Infobox>
                    </Col>
                  );
                })}
              <Col>
                <Infobox>
                  <InfoboxText align="center" variant="p" component="h5" light>
                    {translate('Ean')}
                  </InfoboxText>
                  <InfoboxText align="center" variant="h4" component="p" dense>
                    {gtin}
                  </InfoboxText>
                </Infobox>
              </Col>
              <Col>
                <Infobox>
                  <InfoboxText align="center" variant="p" component="h5" light>
                    {translate('Sales_Unit')}
                  </InfoboxText>
                  <InfoboxText align="center" variant="h4" component="p" dense>
                    {unitQuantity}
                    {unit}
                  </InfoboxText>
                </Infobox>
              </Col>
              {/* {auth.status === 'in' && (
                  <Col>
                    {inStock ? (
                      <StockBox desktopOnly>
                        <StockText>{translate('InStock')}</StockText>
                        <CheckCircle color={theme.Color.green} />
                      </StockBox>
                    ) : (
                      <StockBox desktopOnly>
                        <StockText>{translate('OutOfStock')}</StockText>
                        <AlertCircle color={theme.Color.primary} />
                      </StockBox>
                    )}
                  </Col>
                )} */}
            </InfoboxRow>
          </Accordion>
        </ContentCol>
      </Row>
      <Divider />
      <Drawer>
        <DrawerContent>
          <Row cols={{ col: 1, md: 2 }} gutters={theme.Variables.bigGutter}>
            <Col>
              <Text variant="h4">{translate('Description')}:</Text>
              <Text>{description}</Text>
              {descriptionLong && <Text>{descriptionLong}</Text>}
            </Col>
            {materialDesc && (
              <Col>
                <Text variant="h4">{translate('Material_Description')}:</Text>
                <Text>{materialDesc}</Text>
              </Col>
            )}
            {surfaceDesc && (
              <Col>
                <Text variant="h4">{translate('Surface_Description')}:</Text>
                <Text>{surfaceDesc}</Text>
              </Col>
            )}
            {typeof assemblyInstructions !== 'undefined' &&
              assemblyInstructions.length > 0 && (
                <Col>
                  <Col>
                    <Text variant="h4">
                      {translate('Assembly_Instructions')}:
                    </Text>
                    <Infobox>
                      <Row cols={{ col: 1, lg: 2 }} gutters={15}>
                        {assemblyInstructions.map((instruction, i) => (
                          <StyledInstructionLink key={i}>
                            <IconText>
                              <Pdf size={20} />
                              {instruction.header}
                            </IconText>
                          </StyledInstructionLink>
                        ))}
                      </Row>
                    </Infobox>
                  </Col>
                </Col>
              )}
          </Row>
        </DrawerContent>
        <DrawerHeader
          openedText={translate('Hide_Information')}
          closedText={translate('Show_Information')}
        />
      </Drawer>
    </BackgroundSection>
  );
};

ArticleDescriptionComponent.propTypes = {
  content: PropTypes.object,
  ean: PropTypes.string,
  inStock: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  height: PropTypes.string,
  gtin: PropTypes.string,
  sku: PropTypes.string,
  attributes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  description: PropTypes.string,
  descriptionLong: PropTypes.string,
  materialDescription: PropTypes.string,
  surfaceDescription: PropTypes.string,
  assemblyInstructions: PropTypes.array,
  unit: PropTypes.string,
  unitQuantity: PropTypes.string,
  relatedArticles: PropTypes.arrayOf(PropTypes.object),
};

export default withTheme(ArticleDescriptionComponent);

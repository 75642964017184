import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContentWrapper,
  DrawerContainer,
  DrawerContent,
} from './Drawer.style';
import DrawerContext from './DrawerContext';
import DrawerHeader from './components/DrawerHeader';

const Drawer = ({ closed, ...restProps }) => {
  const [show, setShow] = useState(closed ? false : true);

  const contextValue = React.useMemo(
    () => ({
      show,
      toggle: () => {
        setShow((show) => !show);
      },
    }),
    [show, setShow]
  );

  const children = React.Children.toArray(restProps.children);
  const header = children.pop();

  return (
    <DrawerContainer>
      <DrawerContentWrapper show={show}>{children}</DrawerContentWrapper>
      <DrawerContext.Provider value={contextValue}>
        {typeof header === 'string' ? (
          <DrawerHeader>{header}</DrawerHeader>
        ) : (
          header
        )}
      </DrawerContext.Provider>
    </DrawerContainer>
  );
};

Drawer.propTypes = {
  closed: PropTypes.bool,
};

export { Drawer, DrawerContent, DrawerHeader };

import React, { useContext } from 'react';
import DrawerContext from '../DrawerContext';
import {
  IconWrapper,
  DrawerHeaderSpan,
  DrawerHeaderText,
} from './DrawerHeader.style';
import { ChevronDown } from '@components/Icon';

const DrawerHeaderComponent = ({ openedText, closedText, children }) => {
  const { show, toggle } = useContext(DrawerContext);
  return (
    <DrawerHeaderSpan onClick={() => toggle()}>
      {!children ? (
        <DrawerHeaderText variant="h4" component="p">
          {show ? openedText : closedText}
        </DrawerHeaderText>
      ) : (
        children
      )}
      <IconWrapper show={show}>
        <ChevronDown strokeWidth="2" />
      </IconWrapper>
    </DrawerHeaderSpan>
  );
};

export default DrawerHeaderComponent;

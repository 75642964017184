import styled, { css } from 'styled-components/macro';
import { Text } from '@Omegapoint/components';
import { Container, Row, Col } from '@Omegapoint/components/layout';
import { Infobox } from '@components/atoms';
import { AccordionHeader } from '@components/Accordion';
import { News } from '@components/Icon';

export const TitleText = styled(Text)`
  @media ${({ theme }) => theme.Breakpoints.lg} {
    font-size: 2.1rem;
  }
`;

export const PriceCtaContainer = styled(Row)`
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.Variables.gutter};

  @media ${({ theme }) => theme.Breakpoints.lg} {
    flex-direction: row;
    order: 1;
    margin-top: ${({ theme }) => theme.Variables.gutter};
    margin-bottom: ${({ theme }) => theme.Variables.gutter};
  }
`;

export const PriceWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  height: 100%;

  @media ${({ theme }) => theme.Breakpoints.lg} {
    background-color: ${({ theme }) => theme.Color.articlePriceBg};
    align-items: center;
    margin-right: ${({ theme }) => theme.Variables.smallGutter};
  }
`;

export const BackgroundSection = styled(Container)`
  background-color: ${({ theme }) => theme.Color.articleBg};
  padding: ${({ theme }) => theme.Variables.smallGutter};

  @media ${({ theme }) => theme.Breakpoints.md} {
    padding: ${({ theme }) => theme.Variables.gutter};
  }
  @media ${({ theme }) => theme.Breakpoints.xl} {
    padding: ${({ theme }) => theme.Variables.bigGutter};
  }
  ${({ opacity }) => {
    if (opacity)
      return css`
        opacity: ${opacity};
      `;
  }}
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  min-width: 100%;
  background-color: ${({ theme }) => theme.Color.grey50};
  margin: ${({ theme }) => theme.Variables.smallGutter} 0;

  @media ${({ theme }) => theme.Breakpoints.lg} {
    margin: ${({ theme }) => theme.Variables.gutter} 0;
    display: ${({ mobileOnly }) => mobileOnly && 'none'};
  }
`;

export const IconText = styled(Text)`
  & > *:first-child {
    margin-right: 15px;
  }
`;

export const InfoboxText = styled(Text)`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.Color.text};
  @media ${({ theme }) => theme.Breakpoints.md} {
    font-size: 1rem;
  }
`;

export const StockText = styled(Text)`
  font-size: 0.8rem;
  margin-left: 10px;
`;

export const StockBox = styled(Infobox)`
  flex: 20%;
  display: flex;

  @media ${({ theme }) => theme.Breakpoints.lg} {
    display: ${({ mobileOnly }) => mobileOnly && 'none'};
  }

  ${({ desktopOnly }) => {
    if (desktopOnly)
      return css`
        display: none;

        @media ${({ theme }) => theme.Breakpoints.lg} {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `;
  }}
`;

export const InfoboxRow = styled(Row)`
  @media ${({ theme }) => theme.Breakpoints.lg} {
  }
`;

export const ContentCol = styled(Col)`
  padding-top: ${({ theme }) => theme.Variables.gutter};
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.Breakpoints.md} {
    padding-top: 0;
  }
`;

export const StyledAccordionHeader = styled(AccordionHeader)`
  text-transform: uppercase;
  padding: 10px 5px;
  @media ${({ theme }) => theme.Breakpoints.lg} {
    display: none;
  }
`;

export const StyledInstructionLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

export const RecWrapper = styled.div`
  @media ${({ theme }) => theme.Breakpoints.lg} {
    order: 3;
  }
`;

export const NewsIcon = styled(News)`
  position: absolute;
  left: ${({ theme }) => theme.Variables.smallGutter};
  top: -5px;
  z-index: 1;
`;

export const RelativeCol = styled(Col)`
  position: relative;
  overflow: hidden;
  flex-direction: row;
`;

export const AdditionalImagesRow = styled(Row)`
  @media ${({ theme }) => theme.Breakpoints.md} {
    margin-top: ${({ theme }) => theme.Variables.gutter};
  }
`;

export const ButtonCol = styled(Col)`
  button {
    width: 100%;
    min-height: 100%;
  }
`;

export const CtaRow = styled(Row)`
  @media ${({ theme }) => theme.Breakpoints.md} {
    margin-left: 0;
  }
`;

export const SkeletonPlaceholder = styled.div`
  height: ${({ height }) => (height ? `${height}px` : '30px')};
  background-color: ${({ background }) =>
    background ? background : 'transparent'};
  padding-top: 10px;
  &:before {
    content: ' ';
    display: block;
    height: 3px;
    width: ${({ lineWidth }) => (lineWidth ? `${lineWidth}px` : 0)};
    background-color: ${({ theme }) => theme.Color.grey70};
  }
`;

export const DropdownWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.Variables.gutter};
  @media ${({ theme }) => theme.Breakpoints.md} {
    width: 50%;
  }
`;
